import { Button, Dropdown } from "antd";
import { useState } from "react";
import { DownOutlined } from "@ant-design/icons";

export default function DropdownButton({ label, items, isLoading }) {
  const [open, setOpen] = useState(false);

  const handleDropdownClick = (e) => {
    setOpen(false);
  };

  const menu = {
    items,
    onClick: handleDropdownClick,
  };

  const handleOpenChange = (flag) => {
    setOpen(flag);
  };

  return (
    <Dropdown
      menu={menu}
      open={open}
      trigger={["click"]}
      isLoading={isLoading}
      onOpenChange={handleOpenChange}
    >
      <Button
        style={{ width: "100%" }}
        type="primary"
        block
        onClick={() => setOpen(!open)}
      >
        {label} <DownOutlined />
      </Button>
    </Dropdown>
  );
}
