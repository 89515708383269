import React, { useEffect, useState } from "react";
import { useActionBar } from "../../context/ActionBarContext";
import { Col, Row } from "antd";

import { TableSearch } from "../../components/Base/UPTable";

import ImportEquipmentOffCanvas from "../../components/equipment/OffCanvas/ImportEquipmentOffCanvas";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import EquipmentTable from "./EquipmentTable";
import { get_filter_params } from "../../util/TableStorageService";
import FilterIcon from "../../components/Base/FilterIcon";
import { buildFormDataToFilter } from "../../util/Utils";
import FilterEquipmentOffCanvas from "../../components/equipment/FilterEquipmentOffCanvas";
import { INITIAL_EQUIPMENT_FORM_FILTERS } from "../../util/EquipmentUtils";
import ImportEquipmentGuaranteeOffCanvas from "../../components/equipment/OffCanvas/ImportEquipmentGuaranteeOffCanvas";
import DropdownButton from "../../components/Base/DropdownButton";
import ExportEquipmentReportOffCanvas from "../../components/equipment/OffCanvas/ExportEquipmentReportOffCanvas";
import ExportEquipmentUsageOffCanvas from "../../components/equipment/OffCanvas/ExportEquipmentUsageOffCanvas";

export default function ListEquipment({ clientId = null }) {
  const { changeTitle, setHasBack } = useActionBar();
  const [search, setSearch] = useState(get_filter_params("search"));

  const [openFilter, setOpenFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(1);
  const [_initialFormFilter, setInitialFormFilter] = useState(
    get_filter_params("formFilter", INITIAL_EQUIPMENT_FORM_FILTERS)
  );
  const [formFilter, setFormFilter] = useState(
    buildFormDataToFilter(_initialFormFilter)
  );

  const [openGuaranteeImport, setOpenGuaranteeImport] = useState(false);
  const [openUploandImport, setOpenUploadImport] = useState(false);
  const [openExportEquipmentReport, setOpenExportEquipmentReport] =
    useState(false);
  const [openExportUsageReport, setOpenExportUsageReport] = useState(false);
  const { isDesktop } = useSystemBreakpoint();
  useEffect(() => {
    changeTitle("Visualizar equipamentos");
    setHasBack(false);

    return () => {
      setHasBack(true);
    };
  }, [changeTitle, setHasBack]);

  const exportDropdown = [
    {
      label: "Exportar equipamentos",
      key: "export-equipments",
      onClick: () => setOpenExportEquipmentReport(true),
    },
    {
      label: "Exportar relatório de utilização",
      key: "export-usage",
      onClick: () => setOpenExportUsageReport(true),
    },
  ];

  const importDropdown = [
    {
      label: "Importar garantia",
      key: "import-warranty",
      onClick: () => setOpenGuaranteeImport(true),
    },
    {
      label: "Importar chassis",
      key: "import-chassis",
      onClick: () => setOpenUploadImport(true),
    },
  ];

  return (
    <>
      <Row gutter={[8, 16]} align={"middle"}>
        <Col flex={"auto"}>
          <TableSearch
            size={"large"}
            allowClear
            defaultValue={search}
            onSearch={(value, e) => setSearch(value)}
            onPressEnter={(e) => setSearch(e.target.value)}
            placeholder={"Pesquisa por denominação, nº série ou cliente"}
          />
        </Col>
        <Col
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterIcon count={filterCount} onClick={() => setOpenFilter(true)} />
        </Col>
        <Col xs={24} md={7} lg={5} xl={4}>
          <DropdownButton label="Importar" items={importDropdown} />
        </Col>
        <Col xs={24} md={12} lg={12} xl={4}>
          <DropdownButton label="Exportar" items={exportDropdown} />
        </Col>
      </Row>

      <EquipmentTable
        search={search}
        setFilterCount={setFilterCount}
        clientId={clientId}
        formFilter={formFilter}
      />
      <FilterEquipmentOffCanvas
        open={openFilter}
        initialFormFilter={_initialFormFilter}
        formFilter={formFilter}
        setOpen={setOpenFilter}
        setFormFilter={setFormFilter}
      />
      <ImportEquipmentOffCanvas
        open={openUploandImport}
        setOpen={setOpenUploadImport}
      />
      <ImportEquipmentGuaranteeOffCanvas
        open={openGuaranteeImport}
        setOpen={setOpenGuaranteeImport}
      />
      <ExportEquipmentReportOffCanvas
        open={openExportEquipmentReport}
        setOpen={setOpenExportEquipmentReport}
        formFilter={formFilter}
      />
      <ExportEquipmentUsageOffCanvas
        open={openExportUsageReport}
        setOpen={setOpenExportUsageReport}
      />
    </>
  );
}
