import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import {
  BuildFieldErrorsDict,
  buildFormDataToFilter,
} from "../../../util/Utils";
import { Col, Form, Row } from "antd";
import DividerPage from "../../../components/Base/DividerPage";
import PageTitle from "../../../components/Base/PageTitle";
import Loading from "../../../components/Base/Loading";
import StatisticCard from "../../../components/Base/StatisticCard";
import Bar from "../../../components/Base/Charts/Bar";
import StaticBarGraphic from "../../../components/Base/Charts/StaticBarGraphic";
import { blue_6, mangeta_6, purple_5 } from "../../../Cores";
import Select from "../../../components/Base/Select";
import FilterEquipmentDashboardOffCanvas from "../../../components/FilterEquipmentDashboardOffCanvas";
import FilterIcon from "../../../components/Base/FilterIcon";
import { get_filter_params } from "../../../util/TableStorageService";
import Line from "../../../components/Base/Charts/Line";
import StoppedEquipmentsTable from "../Tables/StoppedEquipmentsTable";
import { formdataToJSON } from "../../../util/Utils";
import { Notification } from "../../../notification";

const GRAF_OPTIONS = [
  {
    value: "0",
    label: "Último mês",
  },
  {
    value: "2",
    label: "Últimos 3 meses",
  },
  {
    value: "5",
    label: "Últimos 6 meses",
  },
  {
    value: "11",
    label: "Último ano ",
  },
];

export default function EquipmentTabDashboard({}) {
  const [isStatisticsLoading, setStatisticsIsLoading] = useState();
  const [isStoppedEquipmentsLoading, setStoppedEquipmentsLoading] =
    useState(true);
  const [statistics, setStatistics] = useState({});
  const [openFilter, setOpenFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(4);
  const [stoppedEquipmentSearch, setStoppedEquipmentSearch] = useState("");
  const initialFormFilter = {};
  const [formFilter, setFormFilter] = useState(
    buildFormDataToFilter(initialFormFilter)
  );
  const [_initialFormFilter, setInitialFormFilter] = useState(
    get_filter_params("formFilter")
  );

  const [stoppedEquipments, setStoppedEquipments] = useState({
    count: 0,
    graphicData: [],
    tableData: [],
  });

  const processStoppedEquipments = (data) => {
    const centers = {};
    let count = 0;

    for (const client of data?.clients) {
      const { center, equipments } = client;

      if (!centers[center]) {
        centers[center] = 0;
      }
      centers[center] += equipments.length;
      count += equipments.length || 0;
    }

    const graphicData = [
      ["Centro", ...Object.keys(centers)],
      ["Equipamentos parados", ...Object.values(centers)],
    ];

    if (graphicData[0].length === 1) {
      graphicData[0].push("Sem equipamentos parados");
      graphicData[1].push(0);
    }

    const tableData = data?.clients?.flatMap(({ center, equipments, name }) => {
      const equipmentsStopped = equipments.map((equipment) => ({
        ...equipment,
        center,
        client: name,
        last_update: data.date,
      }));

      return equipmentsStopped;
    });

    setStoppedEquipments({
      count,
      graphicData,
      tableData,
    });
  };

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        setStatisticsIsLoading(true);
        const response = await api.post(
          `get_equipments_statistics`,
          formFilter
        );
        setStatistics(response.data);
      } catch (error) {
        BuildFieldErrorsDict(error, null, false);
      } finally {
        setStatisticsIsLoading(false);
      }
    };

    const fetchStoppedEquipments = async () => {
      try {
        setStoppedEquipmentsLoading(true);
        const response = await api.post(
          `g4/equipments/get_stopped_equipments/`,
          { ...formdataToJSON(formFilter), status: "parado" }
        );
        processStoppedEquipments(response.data);
      } catch (error) {
        Notification("error", error.response.data.errors);
        processStoppedEquipments({ clients: [] });
      } finally {
        setStoppedEquipmentsLoading(false);
      }
    };

    fetchStatistics();
    fetchStoppedEquipments();
  }, [formFilter]);

  return (
    <>
      <Row>
        <Col xs={24}>
          <DividerPage />
        </Col>
        <Col flex={24} style={{ marginTop: 16 }}>
          <PageTitle isInline value={"Estatísticas da frota"} />
        </Col>

        <Col
          xs={2}
          md={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterIcon count={filterCount} onClick={() => setOpenFilter(true)} />
        </Col>

        <Col xs={24} style={{ marginTop: 8 }}>
          <Row gutter={[24, 24]}>
            {isStatisticsLoading | isStoppedEquipmentsLoading ? (
              <Col xs={24}>
                <Loading />
              </Col>
            ) : (
              <>
                <Col xs={24} md={8} lg={6}>
                  <StatisticCard
                    percent={statistics?.all_equipments_percent}
                    number={statistics?.all_equipments}
                    name={
                      "Equipamentos cadastrados no Uptime Center, em comparação ao mês anterior"
                    }
                  />
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <StatisticCard
                    // percent={statistics?.equipments_with_telemetry_percent}
                    number={statistics?.equipments_with_telemetry}
                    name={"Equipamentos com telemetria"}
                  />
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <StatisticCard
                    percent={statistics?.equipments_actived_percent}
                    number={statistics?.equipments_actived}
                    name={
                      "Equipamentos que reportaram dados telemétricos, em comparação ao mês anterior"
                    }
                  />
                </Col>
                <Col xs={24} md={8} lg={6}>
                  <StatisticCard
                    number={stoppedEquipments.count || null}
                    name={"Equipamentos com status parado"}
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>
        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Row gutter={[16, 8]} style={{ paddingTop: 16 }}>
          <Col xs={24} md={12}>
            <Bar
              url={"/get_equipments_with_opportunity"}
              title={"Equipamentos com notificações"}
              height="400px"
              options={{
                colors: [purple_5],
              }}
              format={"MM/YYYY"}
              filter={formFilter}
              filterRender={
                <Row gutter={[16, 16]} justify={"end"}>
                  <Col xs={24}>
                    <Form.Item name={"months"} initialValue={"2"}>
                      <Select
                        style={{ width: "100%" }}
                        size={"small"}
                        options={GRAF_OPTIONS}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              }
            />
          </Col>

          <Col xs={24} md={1} style={{ marginTop: -16, marginBottom: -16 }}>
            <DividerPage orientation={"vertical"} />
          </Col>
          <Col xs={24} md={11}>
            <Bar
              url={"get_status_comunication_equipments"}
              options={{
                colors: [blue_6, purple_5, mangeta_6],
              }}
              format={"MM/YYYY"}
              title={"Equipamentos que reportaram dados telemétricos"}
              height="400px"
              filter={formFilter}
              filterRender={
                <Row gutter={[16, 16]} justify={"end"}>
                  <Col xs={24}>
                    <Form.Item name={"months"} initialValue={"2"}>
                      <Select
                        style={{ width: "100%" }}
                        size={"small"}
                        options={GRAF_OPTIONS}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>

        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>

        <Row gutter={[24, 24]} style={{ paddingTop: 16, width: "100%" }}>
          {isStoppedEquipmentsLoading ? (
            <Col xs={24}>
              <Loading />
            </Col>
          ) : (
            <>
              <Col xs={24} md={12}>
                <StaticBarGraphic
                  title={"Equipamentos parados"}
                  height="400px"
                  data={stoppedEquipments.graphicData}
                  isLoading={isStoppedEquipmentsLoading}
                  baseColor={blue_6}
                  setSearch={setStoppedEquipmentSearch}
                />
              </Col>
              <Col xs={24} md={12}>
                <StoppedEquipmentsTable
                  data={stoppedEquipments.tableData}
                  isLoading={isStoppedEquipmentsLoading}
                  stoppedEquipmentSearch={stoppedEquipmentSearch}
                  setStoppedEquipmentSearch={setStoppedEquipmentSearch}
                />
              </Col>
            </>
          )}
        </Row>

        <Col xs={24} style={{ marginTop: 16 }}>
          <DividerPage />
        </Col>
        <Col xs={24} md={24} style={{ marginTop: 16 }}>
          <Bar
            url={`get_equipments_with_pmp`}
            options={{
              colors: [purple_5],
            }}
            format={"MM/YYYY"}
            title={"Equipamentos com PMP"}
            width="100%"
            defaultValue={"2"}
            height="404px"
            selectName={"months"}
            filter={formFilter}
            optionsSelect={GRAF_OPTIONS}
          />
        </Col>

        <Col xs={24} md={24} style={{ marginTop: 16 }} hidden>
          <Line
            url={`get_global_hourmeter`}
            title={"Horímetro acumulado"}
            width="100%"
            height="404px"
            hasSelect
            selectName={"months"}
            hasPadding={false}
            filter={formFilter}
            defaultValue={"2"}
            optionsSelect={GRAF_OPTIONS}
          />
        </Col>
      </Row>

      <FilterEquipmentDashboardOffCanvas
        open={openFilter}
        setOpen={setOpenFilter}
        initialFormFilter={_initialFormFilter}
        formFilter={formFilter}
        setFormFilter={setFormFilter}
      />
    </>
  );
}
