import { Button } from "antd";
import OffCanvas from "../../Base/OffCanvas";
import styled from "styled-components";
import { gray_2, text_color } from "../../../Cores";
import { useState } from "react";
import api from "../../../services/api";
import { Notification } from "../../../notification";

const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  color: ${gray_2};
  display: inline-block;
`;

export default function ExportEquipmentReportOffCanvas({
  open,
  setOpen,
  formFilter,
}) {
  const [isExporting, setIsExporting] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const handleExportEquipments = async () => {
    setIsExporting(true);
    try {
      const response = await api.post("equipment/export_xlsx", formFilter);

      Notification("success", response.data.message);
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <OffCanvas
      title={<Title>Exportar relatório de equipamentos</Title>}
      open={open}
      onClose={onClose}
      width={"378px"}
      footer={
        <>
          <Button type="default" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleExportEquipments}
            loading={isExporting}
            disabled={isExporting}
          >
            Exportar
          </Button>
        </>
      }
    >
      <Info>
        Exporte um relatório dos equipamentos do sistema utilizando o filtro
        atual definido na listagem. O relatório será enviado diretamente para o
        e-mail do usuário atual e poderá ser baixado em formato XLSX.
        <br />
        <br />
        <strong>Atenção:</strong> Dependendo da quantidade de equipamentos do
        relatório, a geração pode demorar alguns minutos.
      </Info>
    </OffCanvas>
  );
}
