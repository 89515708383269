import { Button, DatePicker, Form } from "antd";
import OffCanvas from "../../Base/OffCanvas";
import styled from "styled-components";
import { gray_2, input_border, primary_1, text_color } from "../../../Cores";
import { useState } from "react";
import api from "../../../services/api";
import { Notification } from "../../../notification";
import { dateFormat } from "../../../util/dates";

const Title = styled.h4`
  color: ${text_color};
  margin: 0;
`;

const Info = styled.span`
  font-size: 14px;
  margin-top: 8px;
  color: ${gray_2};
  display: inline-block;
`;

const StyledDateInput = styled(DatePicker)`
  border-color: ${input_border};
  background-color: ${primary_1};
`;

export default function ExportEquipmentUsageOffCanvas({ open, setOpen }) {
  const [isExporting, setIsExporting] = useState(false);
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleExportUsage = async () => {
    const { start_date, end_date } = await form.validateFields();

    if (start_date > end_date) {
      Notification(
        "error",
        "Data de início não pode ser maior que a data de término"
      );
      return;
    }

    setIsExporting(true);
    try {
      const response = await api.get("equipment/extract_usage_report", {
        params: {
          start_date: dateFormat(start_date, "yyyy-MM"),
          end_date: dateFormat(end_date, "yyyy-MM"),
        },
      });

      Notification("success", response.data.message);
    } catch (error) {
      console.log(error);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <OffCanvas
      title={<Title>Exportar relatório de utilização dos equipamentos</Title>}
      open={open}
      onClose={onClose}
      width={"378px"}
      footer={
        <>
          <Button type="default" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            type="primary"
            onClick={handleExportUsage}
            loading={isExporting}
            disabled={isExporting}
          >
            Exportar
          </Button>
        </>
      }
    >
      <Info>
        Exporte um relatório de utilização de todos os equipamentos do sistema.
        O relatório será enviado diretamente para o e-mail do usuário atual e
        poderá ser baixado em formato XLSX.
        <br />
        <br />
        <strong>Atenção:</strong> O relatório pode demorar alguns minutos para
        ser gerado.
      </Info>

      <Form layout="vertical" form={form} style={{ marginTop: "2rem" }}>
        <Form.Item label="Data de início" name="start_date">
          <StyledDateInput
            picker="month"
            placeholder="Selecione a data"
            style={{ width: "12rem" }}
            format={"MM/yyyy"}
            rules={[
              {
                required: true,
                message: "Data é obrigatória",
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Data de término" name="end_date">
          <StyledDateInput
            picker="month"
            placeholder="Selecione a data"
            style={{ width: "12rem" }}
            format={"MM/yyyy"}
            rules={[
              {
                required: true,
                message: "Data é obrigatória",
              },
            ]}
          />
        </Form.Item>
      </Form>
      <Info>
        <strong>Atenção:</strong> Dados anteriores a 2020 são escassos e
        inconsistentes. Recomendamos validá-los antes de usá-los.
      </Info>
    </OffCanvas>
  );
}
